<script>
import { required, email } from "vuelidate/lib/validators";
import Modal from "../../../../components/modals/modal-form.vue";
import api from '../../../../helpers/apirest/api';
import PasswordField from "@/components/forms/password-field.vue";

import {
    roleComputed
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        user: {
            name: null,
            email: null,
            role_ids: [],
            password: null,
            repassword: null,
        },
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        PasswordField
    },
    computed: {
        ...roleComputed,  
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            role_ids: { required },
            password: {  },
            repassword: { }
        }
    },
    methods: {
        async shown() {
            this.$store.dispatch("role/loadList");
            if (this.id) {
                const response = await api.get({
                    url: `users/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                const data = response?.data?.data;
                data.role_ids = data?.roles?.map(item => (item?.id));
                this.user = response?.data?.data;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();

            if ( this.$v.$invalid) {
                return;
            }

            const data = this.user;

            await api.save({
                url: 'users',
                id: this.user?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        }
    }
};
</script>

<template>
    <Modal :title="'Formulario de usuario'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal">        
        <div class="card h-100">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre</label>
                    <input
                        id="name"
                        v-model="user.name"
                        type="text"
                        class="form-control"
                        placeholder="Ingresar nombre"
                        :class="{ 'is-invalid': $v.user.name.$error && submitted }"
                    />
                    <div v-if="!$v.user.name.required && submitted" class="invalid-feedback">El nombre es requerido</div>
                </div>
                <div class="form-group">
                    <label for="email">Correo</label>
                    <input
                        id="email"
                        v-model="user.email"
                        type="text"
                        class="form-control"
                        placeholder="Ingresar correo" :class="{ 'is-invalid': $v.user.email.$error && submitted }"
                    />
                    <span v-if="!$v.user.email.required && submitted" class="invalid-feedback">El correo es requerido</span>
                    <span v-else-if="!$v.user.email.email && submitted" class="invalid-feedback">Correo inválido</span>
                </div>
                <div class="form-group">
                    <label for="role_ids">Roles</label>
                    <select
                        multiple
                        v-model="user.role_ids"
                        class="form-control"
                        :class="{ 'is-invalid': $v.user.role_ids.$error && submitted }">
                        <option v-for="option in roles" v-bind:value="option.id" :key="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                    <div v-if="!$v.user.role_ids.required && submitted" class="invalid-feedback">Selecciona al menos un rol</div>
                </div>
                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <PasswordField
                        v-model="user.password"
                        placeholder="Ingresa una contraseña"
                        :enable-validation="false"
                    ></PasswordField>
                </div>
                <div class="form-group">
                    <label for="repassword">Cconfirmar contraseña</label>
                    <input
                        v-model="user.repassword"
                        type="password"
                        class="form-control"
                        id="repassword"
                        placeholder="EIngresa nuevamente tu nueva contraseña"
                        :class="{ 'is-invalid': submitted && $v.user.repassword.$error }"
                    />
                    <div
                        v-if="submitted && $v.user.repassword.$error"
                        class="invalid-feedback"
                    >
                        <div v-if="!$v.user.repassword.required">Requerido</div>
                        <div v-else-if="!$v.user.repassword.sameAs">Contraseñas no coinciden</div>
                    </div>
                </div>
            </div>
        </div>
</Modal></template>